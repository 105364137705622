import { createApp } from 'vue'
import App from './App.vue'
import Vue3TouchEvents from "vue3-touch-events";
import VueCountdown from '@chenfengyuan/vue-countdown';

const app = createApp(App)
app.use(Vue3TouchEvents)
app.component(VueCountdown.name, VueCountdown);

app.config.errorHandler = (err, vm, info) => {
    if (window.localStorage.getItem('state') !== null) {
        window.localStorage.removeItem('state')
        window.location.reload()
    }

    // Call the default error handler
    console.error(`Error caught in global error handler: ${err}\n${info}`)
  }
app.mount('#app')

