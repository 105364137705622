import { onMounted, onUnmounted, ref } from "vue";

export default function useWindowResize() {
  const bHeight = ref(null);
  const bWidth = ref(null);

  function resize() {
    bWidth.value = document.documentElement.clientWidth;
    bHeight.value = document.documentElement.clientHeight;
  }

  onMounted(() => {
    resize()
    window.addEventListener("resize", resize);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", resize);
  });

  return { bWidth, bHeight };
}