<script>
import { ref, onMounted, onUnmounted } from 'vue';
import stickerSheet from './data/stickers.json'
import useWindowResize from './use/useWindowResize.js'
var CryptoJS = require("crypto-js");




export default {
  data(){
    const dateNow = new Date();
    const dateCarveSEA = new Date(dateNow.getFullYear(), 6, 11);

    return{
      timeCarveSEA: dateCarveSEA - dateNow,
      stickers: stickerSheet,
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('reset')) {
      console.log('param: reset triggered');
      try {
        window.localStorage.removeItem('state');
      } catch (e){
        return;
      }
      window.location = window.location.href.split("?")[0];
    }
  },
  setup() {
    const timeoutId = ref(null);
    let urlParams = new URLSearchParams(window.location.search);
    const cyPass = ref("uXV_2JEP8aQ/18279141/" + (urlParams.has('ulcryp') ?  urlParams.get('ulcryp') : ""))
    const {bWidth, bHeight} = useWindowResize()
    const stickerData = ref(Object.assign({}, ...stickerSheet.map((x,i) => ({[x.id]: {
      xv: x.initXV,
      yv: x.initYV,
      prevXV: x.initXV,
      prevYV: x.initYV,
      animation: "shake",
      z: i,
      s: x.s,
      open: x.open,
      altopen: x.altopen
    }}))))
    const stickerKeys=stickerSheet.map(x => x["id"]);
    let dragID = "";

    function isMobileWidth() {
        if( bWidth.value <= 760 ) {
            return true;
        } else {
            return false;
        }
    }

    function isMobileHeight() {
        if( bHeight.value <= 1000 ) {
            return true;
        } else {
            return false;
        }
    }

    function isDesktopWidth() {
        if( bWidth.value >= 1600 ) {
            return true;
        } else {
            return false;
        }
    }

    function konamiCode(e) {
      if( stickerData.value[e.target.id].z >= 89 &&
      stickerData.value['61-614384_anime-kawaii-and-girl-image-transparent-anime-girl-border'].z >= 86 &&
      stickerData.value['d70af7bb-42c7-4df5-a31e-08a9e8f86fe1-removebg-preview-removebg-preview-border'].z >= 85 ){
        console.log('unlocked: konami');
        return true
      }
      return false
    }

    function debugCode() {
      if( stickerData.value['7346577'].z >= 89 &&
      stickerData.value['debug-1-1'].z >= 86 ){
        console.log('unlocked: debug');
        return true
      }
      return false
    }

    // function konamiCodeD(e) {
    //   console.log(stickerData.value[e.target.id].z);
    //   console.log(stickerData.value['61-614384_anime-kawaii-and-girl-image-transparent-anime-girl-border'].z);
    //   console.log(stickerData.value['d70af7bb-42c7-4df5-a31e-08a9e8f86fe1-removebg-preview-removebg-preview-border'].z);
    //   console.log('end');
    // }

    function onStartDragSticker(e) {
      e.preventDefault();
      const isTouch = e.type.startsWith('touch');
      dragID = e.target.id;
      //console.log(e.target)
      stickerData.value[dragID].z = 90;
      for (let i = 0, len = stickerKeys.length; i < len; i++) {
        if (stickerData.value[stickerKeys[i]].z > 11 && stickerData.value[stickerKeys[i]].z < 98) {
          stickerData.value[stickerKeys[i]].z -= 1;
        }
      }
      const clientX = isTouch ? e.touches[0].clientX : e.clientX;
      const clientY = isTouch ? e.touches[0].clientY : e.clientY;
      stickerData.value[dragID].prevXV = (clientX / bWidth.value) * 100;
      stickerData.value[dragID].prevYV = (clientY / bHeight.value) * 100;

      // add event listeners to document object
      document.addEventListener(isTouch ? 'touchmove' : 'mousemove', onDragSticker);
      document.addEventListener(isTouch ? 'touchend' : 'mouseup', onStopDragSticker);
    }

    function onStopDragSticker(e) {
      e.preventDefault();
      dragID = "";

      // remove event listeners from document object
      document.removeEventListener('mousemove', onDragSticker);
      document.removeEventListener('mouseup', onStopDragSticker);
      document.removeEventListener('touchmove', onDragSticker);
      document.removeEventListener('touchend', onStopDragSticker);

      window.localStorage.setItem('state', JSON.stringify(stickerData.value));
    }

    function onDragSticker(e) {
      e.preventDefault();
      const isTouch = e.type.startsWith('touch');
      if (dragID) {
        const clientX = isTouch ? e.touches[0].clientX : e.clientX;
        const clientY = isTouch ? e.touches[0].clientY : e.clientY;
        const deltaX = (clientX / bWidth.value) * 100 - stickerData.value[dragID].prevXV;
        const deltaY = (clientY / bHeight.value) * 100 - stickerData.value[dragID].prevYV;
        stickerData.value[dragID].xv += deltaX;
        stickerData.value[dragID].yv += deltaY;
        stickerData.value[dragID].prevXV = (clientX / bWidth.value) * 100;
        stickerData.value[dragID].prevYV = (clientY / bHeight.value) * 100;
      }
    }

    function onAltClickSticker(e) {
      e.preventDefault();
      if (e.type.startsWith('contextmenu') || (isMobileWidth() && e.type.startsWith('touch'))) {
        dragID = e.target.id;
        switch (stickerData.value[dragID].s){
          case 1.00:
            stickerData.value[dragID].s = 0.75;
            break;
          default:
            stickerData.value[dragID].s = 1.00;
            break;
        }
        dragID = "";

        window.localStorage.setItem('state', JSON.stringify(stickerData.value));
      }
      
    }

    function dblc(e){
      if (isDblclick() == 2 || (isMobileWidth() && e.type.startsWith('touch'))) {
        if ( konamiCode(e) && stickerData.value[e.target.id].altopen != "" ){
          if ( stickerData.value[e.target.id].altopen.startsWith('http') ) {
            window.open(stickerData.value[e.target.id].altopen, '_blank');
          } else if ( stickerData.value[e.target.id].altopen.startsWith('cy:') && cyPass.value != "uXV_2JEP8aQ/18279141/" ) {
            var decryptedBytes = CryptoJS.AES.decrypt(atob(stickerData.value[e.target.id].altopen.slice(3)), cyPass.value);
            var plaintext = decryptedBytes.toString(CryptoJS.enc.Utf8);
            console.log(plaintext)
            if (plaintext.startsWith('http')) {
              try {
                window.open(plaintext, '_blank');
              } catch (e) {
                return;
              }
            }
          } else {
            if ( stickerData.value[e.target.id].open.startsWith('http') ) {
              window.open(stickerData.value[e.target.id].open, '_blank');
            }
          }
        } else {
          if ( stickerData.value[e.target.id].open.startsWith('http') ) {
            window.open(stickerData.value[e.target.id].open, '_blank');
          } else {
            switch (stickerData.value[e.target.id].open) {
              case 'reset':
                if ( debugCode() ) {
                  console.log('open: reset state');
                  try {
                    window.localStorage.removeItem('state');
                  } catch (e){
                    return;
                  }
                  location.reload();
                }
                break;
              case 'reload':
                console.log('open: reload');
                location.reload();
                break;
              default:
                break;
            }
          }
          
        }
      }
    }

    function isDblclick() {
      if(!timeoutId.value) {
          timeoutId.value = setTimeout(() => {
                timeoutId.value = null;
                return 1;
            }, 5);//tolerance in ms
        } else{
            clearTimeout(timeoutId.value);
            timeoutId.value = null;
            return 2;
        }
      }

    onMounted(() => {
      // add event listeners to document object on mount
      document.addEventListener('mousemove', onDragSticker);
      document.addEventListener('mouseup', onStopDragSticker);

      // load saved state
      const savedValue = window.localStorage.getItem('state');
      
      if (savedValue !== null) {
        try {
          stickerData.value = JSON.parse(savedValue);
        } catch (e) {
          window.localStorage.removeItem('state')
        }
      }
    });

    onUnmounted(() => {
      // remove event listeners from document object on unmount
      document.removeEventListener('mousemove', onDragSticker);
      document.removeEventListener('mouseup', onStopDragSticker);
    });

    function getImg(img) {
      if (img.startsWith("http")) {
        return img
      }
      return require(`./assets/${img}`)
    }

    return {
      bWidth,
      bHeight,
      stickerData,
      stickerKeys,
      isMobileWidth,
      isMobileHeight,
      isDesktopWidth,
      onStartDragSticker,
      onStopDragSticker,
      onAltClickSticker,
      //konamiCodeD, v-on:click.left="konamiCodeD"
      dblc,
      getImg,
    };
  }
};
</script>

<template>
<div class="top-bar textpop"><vue-countdown :time="timeCarveSEA" :interval="100" v-slot="{ days, hours, minutes }" :style="{}">
  CarveSEA @ {{ days }} days, {{ hours }} hrs, {{ minutes }} mins
</vue-countdown> </div>

<div class="container">
  <div class="background">  </div>
</div>

<div class="photo" v-on:dblclick.left="dblc"
 v-touch:tap="dblc" 
 @contextmenu.prevent="onAltClickSticker"
 v-touch:longtap="onAltClickSticker"
 v-for="sticker in stickers"
  :id=sticker.id
  :key=sticker.id :style="{
    position: 'absolute',
    left: `${stickerData[sticker.id].xv}vw`,
    top: `${stickerData[sticker.id].yv}vh`,
    zIndex: `${stickerData[sticker.id].z}`,
    width: `${(isMobileWidth() ? isMobileHeight() ? 200 * 0.75 : 200 : isDesktopWidth() ? 400 : 300) * stickerData[sticker.id].s}px`,
    height: 'fit-content',
    cursor: 'pointer',
  }"
  @mousedown="onStartDragSticker"
  @mouseover="onMouseOverSticker"
  @touchstart="onStartDragSticker"

  >
    <img :class="'noselect '+stickerData[sticker.id].animation" 
    :src=getImg(sticker.url) 
    :style="{maxWidth: '100%', maxHeight: '100%', pointerEvents: 'none', 
    WebkitFilter: 'sepia(30%) brightness(98%) hue-rotate(0deg) saturate(300%) contrast(75%)',
    }">

</div>

<div class="bottom-bar" data-bottombar="attr">
  <div class="bottom-bar__text">
    <a href="https://www.freepik.com/free-photo/torn-posters_1034040.htm#query=grafitti&position=15&from_view=keyword&track=sph">Image by kues1</a> on Freepik
  </div>
</div>

</template>

<!-- 
  font | https://wordmark.it/
  google fonts | https://fonts.google.com/
  palette | https://coolors.co/390099-9e0059-ff0054-ff5400-ffbd00
  boardize | https://borderize.com/
  rmbackground | https://www.remove.bg/
  bgeraser | https://bgeraser.com/
  img2img | https://replicate.com/stability-ai/stable-diffusion-img2img
  upscale | https://bigjpg.com/

-->
<!-- https://freefrontend.com/css-text-effects/ 
https://codepen.io/cecilehabran/pen/EvRamr
https://codepen.io/raylopro/pen/JOBBrX
https://codepen.io/TiagoLopes/pen/vZBMWB
https://codepen.io/bennettfeely/pen/DOexxp -->


<style scoped>
/* Strip styling from anchor links */
a {
  color: white;
  text-decoration: none;
}

/* Code to strip default interactions */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Sticker intro animation */
.shake { 
  animation: shake 0.69s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Sticker hover animation */
.photo{
  position: absolute;
  
}

.photo img{
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: scale(1.0);
}

.photo:hover img{
  transform: scale(1.05);
}

/* Container to fill */
.container {
  /* Fill entire page and ghost*/
  height: 100vh;
  min-height: 100%;
  background-color: transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /* Hide scrollbars */
  overflow: hidden; 
}

.background {
  background-image: url(./assets/out-071c50-min.png);

  /* Tints image */
  -webkit-filter: blur(0px) grayscale(5%) sepia(00%) brightness(100%) hue-rotate(40deg) saturate(300%) contrast(65%);

  /* Fills entire page */
  height: 100vh;
  min-height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /* Hide scrollbars */
  overflow: hidden; 
}

@import url('https://fonts.googleapis.com/css2?family=Anybody:wght@700&display=swap');
/* Esk8 word */
.titlestrip {
  top: 30%;
  min-width: 100%; 
  text-align:center;  
  position: absolute;
  z-index: auto;
}

.title {
  font-family: "Segoe UI Black";
  font-family: 'Anybody', cursive;

  font-size: 11em;
	color: white;
  text-shadow: 0px 4px 4px #282828,
   4px 4px 20px aqua,
   4px 4px 50px #282828;

  position: relative;
  z-index: 100;
  
}

.textpop {
  font-family: "Segoe UI Black";
  font-family: 'Anybody', cursive;

  font-size: 1em;
	color: white;

  position: relative;

}


/* Fancy text effect | https://codepen.io/antoniasymeonidou/pen/wvPvMve */
.overline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-image: linear-gradient(#FFBD00,#FFBD00);
  background-size: 100% 20px;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  transition: background-size .7s, background-position .5s ease-in-out;
}

.overline:hover {
  background-size: 100% 100%;
  background-position: 0% 100%;
  transition: background-position .7s, background-size .5s ease-in-out;
}

.top-bar {
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  

  opacity: 100%;
  background: rgb(17, 125, 239);
  color: #ffffff;
  box-sizing: border-box;
  z-index: 1;
}

/* Required attribution */
.bottom-bar {
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 300px;
  width: 100%;
  opacity: 60%;
  color: #ffffff;
  box-sizing: border-box;
  z-index: 1;
}



.bottom-bar__text {
  opacity: 60%;
  font-size: 1em;
  text-align: right;
}
</style>

